import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Fragment from "../../utils/Fragment";
import { Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { useHistory, useParams, Redirect } from "react-router-dom";
import Select from "react-select";
import * as actions from "../../store/actions/index";
import "react-toastify/dist/ReactToastify.css";
import "./AddEvent.css";

const AddEvent = (props) => {
  const [addBtnClick, setAddBtnClick] = useState(false);
  const [dashItem, setDashItem] = useState("");
  const [location, setLocation] = useState("");
  const [startdate, setStartdate] = useState("");
  const [enddate, setEnddate] = useState("");
  const [starttime, setStarttime] = useState("");
  const [endtime, setEndtime] = useState("");
  const [timezone, setTimezone] = useState("");
  const [additionaldetails, setAdditionaldetails] = useState("");
  const [btnEnabled, setBtnEnabled] = useState(true);
  const [redirectChapter, setRedirectChapter] = useState(false)

  const [permissionAssigned, setPermissionAssigned] = useState({
    value: "",
    label: "",
  });

  const chapteroptions = [{ value: "All Users", label: "All Users" }];

  const dispatch = useDispatch();
  const reduxState = useSelector((state) => state);

  const [formData, setFormData] = useState({
    dashItem: "",
    location: "",
    startdate: "",
    enddate: "",
    starttime: "",
    endtime: "",
    timezone: "",
    additionaldetails: "",
    btnEnabled: "",
    chapter_dashboard: []
  });

  const onChange = (e) => {
    const { name, value } = e.target;

    if (formData.dashItem.length > 1) {
      setBtnEnabled(false);
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };


  let onChangeAddChapter = (selectedOption) => {
    console.log("onChangeAddChapter");
    console.log(selectedOption);
    console.log(selectedOption.length);


    for(var i = 0; i < selectedOption.length; i++){
    if(selectedOption[i].value.includes("-Create New Chapter-")){
      setRedirectChapter(true);
    }
  }

    let reformattedchapters = [];

    try {
      for (var i = 0; i < selectedOption.length; i++) {
        reformattedchapters.push({
          chapter_dash_names: selectedOption[i].value,
        });
      }
    } catch {
      console.log("push error - reformattedchapters");
    }

    console.log("reformattedchapters");
    console.log(reformattedchapters);

    setFormData({
      ...formData,
      chapter_dashboard: reformattedchapters,
    });

    console.log("past it");
    // this.setState({ selectedOption });
  };


  const onAdd = () => {

    console.log("addingevent")
    console.log("formData.chapter_dashboard")
    console.log(formData.chapter_dashboard)

    onClick();

    try {
      toast.success(<h5> Event Created!</h5>, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      dispatch(
        actions.createDashboard({
          name: formData.dashItem,
          location: formData.location,
          startdate: formData.startdate,
          enddate: formData.enddate,
          starttime: formData.starttime,
          endtime: formData.endtime,
          timezone: formData.timezone,
          additionaldetails: formData.additionaldetails,
          chapter_dashboard: formData.chapter_dashboard,
        })
      );

      console.log("double check")
      console.log(formData.chapter_dashboard)
    } catch {
      toast.error(<h5> An error occured creating the event.</h5>, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }

setTimeout(() => {
  console.log("two second delay")
  dispatch(actions.fetchDashboard());
}, 1000);

  };

  const onClick = () => {
    setAddBtnClick(!addBtnClick);
  };

  const timezones = [
    "Mountain Time (US and Canada) - America/Denver (UTC-07:00)",
    "Central Time (US and Canada) - America/Chicago (UTC-06:00)",
    "Eastern Time (US and Canada) - America/New_York (UTC-05:00)",
    "International Date Line West - Etc/GMT+12 (UTC-12:00)",
    "Coordinated Universal Time-11 - Etc/GMT+11 (UTC-11:00)",
    "Hawaii - Pacific/Honolulu (UTC-10:00)",
    "Alaska - America/Anchorage (UTC-09:00)",
    "Baja California - America/Santa_Isabel (UTC-08:00)",
    "Pacific Time (US and Canada) - America/Los_Angeles (UTC-08:00)",
    "Chihuahua, La Paz, Mazatlan - America/Chihuahua (UTC-07:00)",
    "Arizona - America/Phoenix (UTC-07:00)",
    "Central America - America/Guatemala (UTC-06:00)",
    "Saskatchewan - America/Regina (UTC-06:00)",
    "Guadalajara, Mexico City, Monterey - America/Mexico_City (UTC-06:00)",
    "Bogota, Lima, Quito - America/Bogota (UTC-05:00)",
    "Indiana (East) - America/Indiana/Indianapolis (UTC-05:00)",
    "Caracas - America/Caracas (UTC-04:30)",
    "Atlantic Time (Canada) - America/Halifax (UTC-04:00)",
    "Asuncion - America/Asuncion (UTC-04:00)",
    "Georgetown, La Paz, Manaus, San Juan - America/La_Paz (UTC-04:00)",
    "Cuiaba - America/Cuiaba (UTC-04:00)",
    "Santiago - America/Santiago (UTC-04:00)",
    "Newfoundland - America/St_Johns (UTC-03:30)",
    "Brasilia - America/Sao_Paulo (UTC-03:00)",
    "Greenland - America/Godthab (UTC-03:00)",
    "Cayenne, Fortaleza - America/Cayenne (UTC-03:00)",
    "Buenos Aires - America/Argentina/Buenos_Aires (UTC-03:00)",
    "Montevideo - America/Montevideo (UTC-03:00)",
    "Coordinated Universal Time-2 - Etc/GMT+2 (UTC-02:00)",
    "Cape Verde - Atlantic/Cape_Verde (UTC-01:00)",
    "Azores - Atlantic/Azores (UTC-01:00)",
    "Casablanca - Africa/Casablanca (UTC+00:00)",
    "Monrovia, Reykjavik - Atlantic/Reykjavik (UTC+00:00)",
    "Dublin, Edinburgh, Lisbon, London - Europe/London (UTC+00:00)",
    "Coordinated Universal Time - Etc/GMT (UTC+00:00)",
    "Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna - Europe/Berlin (UTC+01:00)",
    "Brussels, Copenhagen, Madrid, Paris - Europe/Paris (UTC+01:00)",
    "West Central Africa - Africa/Lagos (UTC+01:00)",
    "Belgrade, Bratislava, Budapest, Ljubljana, Prague - Europe/Budapest (UTC+01:00)",
    "Sarajevo, Skopje, Warsaw, Zagreb - Europe/Warsaw (UTC+01:00)",
    "Windhoek - Africa/Windhoek (UTC+01:00)",
    "Athens, Bucharest, Istanbul - Europe/Istanbul (UTC+02:00)",
    "Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius - Europe/Kiev (UTC+02:00)",
    "Cairo - Africa/Cairo (UTC+02:00)",
    "Damascus - Asia/Damascus (UTC+02:00)",
    "Amman - Asia/Amman (UTC+02:00)",
    "Harare, Pretoria - Africa/Johannesburg (UTC+02:00)",
    "Jerusalem - Asia/Jerusalem (UTC+02:00)",
    "Beirut - Asia/Beirut (UTC+02:00)",
    "Baghdad - Asia/Baghdad (UTC+03:00)",
    "Minsk - Europe/Minsk (UTC+03:00)",
    "Kuwait, Riyadh - Asia/Riyadh (UTC+03:00)",
    "Nairobi - Africa/Nairobi (UTC+03:00)",
    "Tehran - Asia/Tehran (UTC+03:30)",
    "Moscow, St. Petersburg, Volgograd - Europe/Moscow (UTC+04:00)",
    "Tbilisi - Asia/Tbilisi (UTC+04:00)",
    "Yerevan - Asia/Yerevan (UTC+04:00)",
    "Abu Dhabi, Muscat - Asia/Dubai (UTC+04:00)",
    "Baku - Asia/Baku (UTC+04:00)",
    "Port Louis - Indian/Mauritius (UTC+04:00)",
    "Kabul - Asia/Kabul (UTC+04:30)",
    "Tashkent - Asia/Tashkent (UTC+05:00)",
    "Islamabad, Karachi - Asia/Karachi (UTC+05:00)",
    "Sri Jayewardenepura Kotte - Asia/Colombo (UTC+05:30)",
    "Chennai, Kolkata, Mumbai, New Delhi - Asia/Kolkata (UTC+05:30)",
    "Kathmandu - Asia/Kathmandu (UTC+05:45)",
    "Astana - Asia/Almaty (UTC+06:00)",
    "Dhaka - Asia/Dhaka (UTC+06:00)",
    "Yekaterinburg - Asia/Yekaterinburg (UTC+06:00)",
    "Yangon - Asia/Yangon (UTC+06:30)",
    "Bangkok, Hanoi, Jakarta - Asia/Bangkok (UTC+07:00)",
    "Novosibirsk - Asia/Novosibirsk (UTC+07:00)",
    "Krasnoyarsk - Asia/Krasnoyarsk (UTC+08:00)",
    "Ulaanbaatar - Asia/Ulaanbaatar (UTC+08:00)",
    "Beijing, Chongqing, Hong Kong, Urumqi - Asia/Shanghai (UTC+08:00)",
    "Perth - Australia/Perth (UTC+08:00)",
    "Kuala Lumpur, Singapore - Asia/Singapore (UTC+08:00)",
    "Taipei - Asia/Taipei (UTC+08:00)",
    "Irkutsk - Asia/Irkutsk (UTC+09:00)",
    "Seoul - Asia/Seoul (UTC+09:00)",
    "Osaka, Sapporo, Tokyo - Asia/Tokyo (UTC+09:00)",
    "Darwin - Australia/Darwin (UTC+09:30)",
    "Adelaide - Australia/Adelaide (UTC+09:30)",
    "Hobart - Australia/Hobart (UTC+10:00)",
    "Yakutsk - Asia/Yakutsk (UTC+10:00)",
    "Brisbane - Australia/Brisbane (UTC+10:00)",
    "Guam, Port Moresby - Pacific/Port_Moresby (UTC+10:00)",
    "Canberra, Melbourne, Sydney - Australia/Sydney (UTC+10:00)",
    "Vladivostok - Asia/Vladivostok (UTC+11:00)",
    "Solomon Islands, New Caledonia - Pacific/Guadalcanal (UTC+11:00)",
    "Coordinated Universal Time+12 - Etc/GMT-12 (UTC+12:00)",
    "Fiji, Marshall Islands - Pacific/Fiji (UTC+12:00)",
    "Magadan - Asia/Magadan (UTC+12:00)",
    "Auckland, Wellington - Pacific/Auckland (UTC+12:00)",
    "Nuku'alofa - Pacific/Tongatapu (UTC+13:00)",
    "Samoa - Pacific/Apia (UTC+13:00)",
  ];

  let headersdate = ["Start Date", "End Date"];

  let headerstime = ["Start Time", "End Time"];

  let chapters_array = ["Chapter Permission"];


  try {
    let chapters_created = reduxState.auth.user.chapter.map(
      (arr) => arr.chapter_names
    );

    for (var i = 0; i < chapters_created.length; i++) {
      chapteroptions.push({
        value: chapters_created[i],
        label: chapters_created[i],
      });
    }
    chapteroptions.push({
      value: "-Create New Chapter-",
      label: "-Create New Chapter-",
    });
  } catch {
    console.log("error");
  }

  let chapterpage_redirect = null;
  if (redirectChapter) {
    chapterpage_redirect = <Redirect to="/chapters" />;
  }

  let addBtn = (
    <div className="">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <ToastContainer />
      <div style={{ textAlign: "center" }}>
        <Button
          className="createeventbutton"
          variant="light"
          onClick={onClick}
          style={{
            marginTop: 15,
            borderRadius: 29,
            padding: 14,
            border: ".9px solid darkgray",
            boxShadow: "1px 3px 8px dimgray",
          }}
        >
          <div
            style={{
              // fontWeight: "bold",
              fontSize: "20px",
              color: "#606060",
              fontFamily: "Verdana, Georgia, Tahoma",
            }}
          >
            Create Event
          </div>
        </Button>
      </div>
    </div>
  );

  if (addBtnClick) {
    addBtn = (
      <div className="eventform">
        <div
          style={{
            border: "1.5px solid #C0C0C0",
            backgroundColor: "white",
            borderRadius: 10,
          }}
          className=""
        >
          <div
            onClick={onClick}
            className="close_event_form_icon"
            style={{ marginRight: 20, marginTop: 10 }}
          >
            <i className="fas fa-remove fa-sm"></i>
          </div>
          <div
            className="my-2 my-lg-0 addBtn createeventinputs"
            style={{
              paddingTop: 40,
              paddingLeft: 40,
              backgroundColor: "whites",
            }}
          >
            <label className="eventcreatelabels" htmlFor="dashItem">
              <b style={{ fontFamily: "Verdana" }}>Event Name</b>
            </label>
            <label className="asterisk" htmlFor="dashItem">
              *
            </label>
            <input
              id="dashItem"
              className="form-control mr-sm-2"
              name="dashItem"
              style={{ width: "70%", marginBottom: 20, fontFamily: "Verdana" }}
              placeholder="Annual Gala"
              onChange={(e) => onChange(e)}
            />
            <label className="eventcreatelabels" htmlFor="location">
              <b style={{ fontFamily: "Verdana" }}>Location</b>
            </label>
            <input
              id="location"
              className="form-control mr-sm-2"
              name="location"
              style={{
                width: "70%",
                marginBottom: 20,
                marginTop: 5,
                fontFamily: "Verdana",
              }}
              placeholder="1rst St. Chicago, IL 60626"
              onChange={(e) => onChange(e)}
            />

            <table className="prTable" style={{ marginRight: -5 }}>
              {/* <thead style={{backgroundColor: "#F8F8F8"}}> */}
              <thead>
                <tr className="prTableHeader">
                  {headersdate.map((header) => (
                    <th
                      style={{
                        // color: "#484848",
                        color: "black",
                        background: "none",
                        fontFamily: "Verdana",
                        border: "none",
                      }}
                    >
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody style={{}}>
                {chapters_array.map((item) => (
                  <tr
                    className=""
                    style={{ color: "#404040" }}
                    // onClick={()=>initiateEditModal(item)}
                  >
                    <td
                      key={item.chapter_names}
                      className="chapterTableCell chapters_field"
                      style={{
                        border: "none",
                        background: "none",
                        fontSize: "13px",
                        paddingRight: 18,
                        paddingTop: 18,
                        paddingBottom: 18,
                        paddingLeft: 10,
                        fontFamily:
                          "Verdana, Arial, Tahoma, Garamond, Trebuchet, Droid Sans",
                        // background: item.status === "Checked In" ? "#b9ecdb" : null,
                      }}
                    >
                      <div
                        style={{ marginLeft: -10, marginRight: -50 }}
                        onClick={(e) => console.log(item)}
                      >
                        <input
                          id="startdate"
                          type="date"
                          className="form-control mr-sm-2"
                          name="startdate"
                          style={{ width: "68%", fontFamily: "Verdana" }}
                          placeholder="Enter Start Date"
                          onChange={(e) => onChange(e)}
                        />
                      </div>
                    </td>
                    <td
                      key={item.chapter_descriptions}
                      className="chapterTableCell description_field"
                      style={{
                        border: "none",
                        background: "none",
                        fontSize: "13px",
                        paddingRight: 18,
                        paddingTop: 18,
                        paddingBottom: 18,
                        paddingLeft: 10,
                        fontFamily:
                          "Verdana, Arial, Tahoma, Garamond, Trebuchet, Droid Sans",
                        // background: item.status === "Checked In" ? "#b9ecdb" : null,
                        // color: item.status === "Checked In" ? "darkgreen" : null,
                        // fontWeight: item.status === "Checked In" ? "bold" : null,
                      }}
                    >
                      <div
                        style={{ marginLeft: -7 }}
                        onClick={(e) => console.log(item)}
                      >
                        <input
                          id="enddate"
                          type="date"
                          className="form-control mr-sm-2"
                          name="enddate"
                          style={{ width: "73%", fontFamily: "Verdana" }}
                          placeholder="Enter End Date"
                          onChange={(e) => onChange(e)}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <table className="prTable" style={{ marginRight: -5 }}>
              {/* <thead style={{backgroundColor: "#F8F8F8"}}> */}
              <thead>
                <tr className="prTableHeader">
                  {headerstime.map((header) => (
                    <th
                      style={{
                        minWidth: 65,
                        // color: "#484848",
                        color: "black",
                        background: "none",
                        fontFamily: "Verdana",
                        border: "none",
                      }}
                    >
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody style={{}}>
                {chapters_array.map((item) => (
                  <tr
                    className=""
                    style={{ color: "#404040" }}
                    // onClick={()=>initiateEditModal(item)}
                  >
                    <td
                      key={item.chapter_names}
                      className="chapterTableCell chapters_field"
                      style={{
                        border: "none",
                        background: "none",
                        fontSize: "13px",
                        paddingRight: 18,
                        paddingTop: 18,
                        paddingBottom: 18,
                        paddingLeft: 10,
                        fontFamily:
                          "Verdana, Arial, Tahoma, Garamond, Trebuchet, Droid Sans",
                        // background: item.status === "Checked In" ? "#b9ecdb" : null,
                      }}
                    >
                      <div
                        style={{ marginLeft: -10, marginRight: -50 }}
                        onClick={(e) => console.log(item)}
                      >
                        <input
                          type="time"
                          id="starttime"
                          style={{ width: "68%", fontFamily: "Verdana" }}
                          className="form-control mr-sm-2"
                          name="starttime"
                          placeholder="Enter Start Time"
                          onChange={(e) => onChange(e)}
                        />
                      </div>
                    </td>
                    <td
                      key={item.chapter_descriptions}
                      className="chapterTableCell description_field"
                      style={{
                        border: "none",
                        background: "none",
                        fontSize: "13px",
                        paddingRight: 18,
                        paddingTop: 18,
                        paddingBottom: 18,
                        paddingLeft: 10,
                        fontFamily:
                          "Verdana, Arial, Tahoma, Garamond, Trebuchet, Droid Sans",
                        // background: item.status === "Checked In" ? "#b9ecdb" : null,
                        // color: item.status === "Checked In" ? "darkgreen" : null,
                        // fontWeight: item.status === "Checked In" ? "bold" : null,
                      }}
                    >
                      <div
                        style={{ marginLeft: -7 }}
                        onClick={(e) => console.log(item)}
                      >
                        <input
                          type="time"
                          id="endtime"
                          className="form-control mr-sm-2"
                          name="endtime"
                          style={{ width: "73%", fontFamily: "Verdana" }}
                          placeholder="Enter End Time"
                          onChange={(e) => onChange(e)}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <label className="eventcreatelabels" htmlFor="timezone">
              <b style={{ fontFamily: "Verdana" }}>Timezone</b>
            </label>
            {/* <input
            type="select"
              id="timezone"
              className="form-control mr-sm-2"
              name="timezone"
              style={{ width: "50%", marginBottom: 20 }}
              placeholder="Enter Timezone"
              onChange={(e) => onChange(e)}
            /> */}
            <select
              id="timezone"
              className="form-control mr-sm-2"
              name="timezone"
              style={{ width: "50%", marginBottom: 20, fontFamily: "Verdana" }}
              placeholder="Enter Timezone"
              onChange={(e) => onChange(e)}
            >
              <option selected="selected"></option>
              {timezones.map((x, y) => (
                <option key={y}>{x}</option>
              ))}
            </select>


            <div style={{width: "80%"}} className="form-group">
                      <label htmlFor="chapter">
                        <b>Chapters</b>
                      </label>
                      <Select
                        isMulti
                        // defaultValue={permissionAssigned}
                        onChange={onChangeAddChapter}
                        options={chapteroptions}
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            // borderColor: state.isFocused ? 'grey' : 'red',
                            borderColor: "gray",
                            fontSize: 16,
                            height: 0,
                            marginLeft: 0,
                            marginTop: -8,
                          }),
                        }}
                      />
                    </div>


                    
            <label className="eventcreatelabels" htmlFor="additionaldetails">
              <b style={{ fontFamily: "Verdana" }}>Additional Details</b>
            </label>
            <textarea
              id="additionaldetails"
              className="form-control mr-sm-2"
              name="additionaldetails"
              style={{
                width: "50%",
                marginBottom: 20,
                height: 60,
                fontFamily: "Verdana",
              }}
              onChange={(e) => onChange(e)}
            />
            <div
              style={{
                marginLeft: -40,
                display: "flex",
                justifyContent: "space-evenly",
              }}
            >
              <Button
                id="eventcancelbutton"
                onClick={onClick}
                variant="danger"
                className="eventcreatebuttons"
                style={{
                  height: 50,
                  marginTop: 25,
                  marginBottom: 28,
                  marginRight: "20%",
                  borderRadius: 29,
                  width: 180,
                  border: "1px solid black",
                  boxShadow: "0px 2px 4px #505050",
                }}
              >
                <i className="fas fa-remove fa-sm"></i>
                <b style={{ fontFamily: "Verdana" }}> Cancel</b>
              </Button>
              <Button
                disabled={btnEnabled}
                onClick={onAdd}
                variant="light"
                style={{
                  height: 50,
                  marginTop: 25,
                  borderRadius: 29,
                  padding: 14,
                  width: 180,
                  border: ".9px solid darkgray",
                  boxShadow: "1px 3px 8px dimgray",
                }}
              >
                <div id="eventcreatebutton" className="eventcreatebuttons">
                  <i className="fas fa-plus fa-sm"></i>
                  <b style={{ fontFamily: "Verdana" }}> Create Event</b>
                </div>
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return <Fragment>{chapterpage_redirect}{addBtn}</Fragment>;
};

export default AddEvent;
