import React, { useState, useEffect } from "react";
import Fragment from "../../../utils/Fragment";
// import { validation } from "../../../utils/Validation";
// import Spinner from '../../Spinner/Spinner'
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams, Redirect } from "react-router-dom";
// import * as actions from "../../../store/actions/index";
import "./Volunteer.css";
import axios from "axios";
import { isEmpty } from "../../../utils/Validation";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Button from "react-bootstrap/Button";
import DeleteVolunteerModal from "./DeleteVolunteerModal";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import CreateVolunteer from "./CreateVolunteer";
import RingLoader from "react-spinners/RingLoader";
import "./Rolesignup.css";
// import styled from "@emotion/styled";

// const divStyle = {
//   border: ".8px solid black",
//   fontSize: "16px",
//   padding: 1,
//   width: 200,
// };

const Volunteer = (props) => {
  const reduxState = useSelector((state) => state);
  const dispatch = useDispatch();
  const history = useHistory();

  const [associatedUsers, setAssociatedUsers] = useState([]);
  const [addModalShow, setAddModalShow] = useState(false);
  const [editModalShow, setEditModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [actualid, setActualid] = useState("");
  const [deletingVolunteer, setDeletingVolunteer] = useState("");
  const [editedVolunteer, setEditedVolunteer] = useState([]);
  const [currentusername, setCurrentusername] = useState("");
  const [resetPassword, setResetPassword] = useState(false);


  const [passwordLength, setPasswordLength] = useState(false);
  const [passwordMatches, setPasswordMatches] = useState(false);
  const [passwordNumber, setPasswordNumber] = useState(false);
  const [passwordUppercase, setpasswordUppercase] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [disableNewPassword, setDisableNewPassword] = useState(true);

  // Username Search States
  const [usernameerror, setUsernameerror] = useState(false)
  const [startsearch, setStartsearch] = useState(false)
  const [usernameAvailable, setUsernameAvailable] = useState(false)
  const [usernamecharerror, setUsernamecharerror] = useState(false)
  const [createdisable, setCreatedisable] = useState(true)
  const [redirectChapter, setRedirectChapter] = useState(false)

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    username: "",
    currentusername: "",
    chapter_volunteer: [],
    permission: "",
    email: "",
    password: "",
    chapters_assigned: [],
  });

  const [permissionsData, setPermissionsData] = useState({
    value: "",
    label: "",
  });

  const [permissionAssigned, setPermissionAssigned] = useState({
    value: "",
    label: "",
  });


  let authRedirect = null;
  if (!reduxState.auth.isAuthenticated) {
    authRedirect = <Redirect to="/" />;
  }

  useEffect(() => {
    axios
      .get("/api/users/getadmins")
      .then((res) => {
        console.log("admins");
        console.log(res.data.user);
        

   let allowed_volunteers = []

   if(reduxState.auth.user.role === "Owner Original") {
    allowed_volunteers = res.data.user;
  }

  else{

    // Chapter Specific Access
    let userChaptersAssigned = reduxState.auth.user.chapter_volunteer.map(
      (arr) => arr.chapters_assigned
    );
    console.log("userChaptersAssigned")
    console.log(userChaptersAssigned)

    // Here we are filtering all volunteers by those that also match the current volunteer's chapters assigned.
    let matching_userchapter = res.data.user.filter((item)=>item.chapter_volunteer.map((items)=>items.chapters_assigned).some(r=> userChaptersAssigned.includes(r)))
    
    if(!userChaptersAssigned.includes("All Events")){
    // Here we are filtering all volunteers by those that are assigned to view "All Events"
    let matching_userchapter2 = res.data.user.filter((item)=>item.chapter_volunteer.map((items)=>items.chapters_assigned).includes("All Events"))
    let merged = [...matching_userchapter, ...matching_userchapter2];  
    allowed_volunteers = merged
    }
    else{
    allowed_volunteers = matching_userchapter
    }


   

    
  }

  setAssociatedUsers(allowed_volunteers);
    
      })
      .catch((err) => {
        console.log(err.response);
      });

    // return () => {
    //   second
    // }
  }, []);

  let onChangeEdit = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const onSubmitResetPassword = () => {

    let postData = {
      password: formData.password,
      volunteer_id: actualid
    }

    axios.post('/api/users/resetvolpassword', postData)
    .then(res => {
      console.log("axios response")
      console.log(res)

      toast.success(
        <div style={{ fontSize: "16px" }}>
          <b>Volunteer Password has been Reset</b>
        </div>,
        {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
    })
    .catch(err =>{
      console.log(err)
    });

    setEditModalShow(false)


  }

  let onChangeUsernameAdd = (e) => {
    e.persist()
    const { name, value } = e.target;

    setUsernameAvailable(false)
    
    setFormData({
        ...formData,
        [name]: value,
      });

      console.log("formData")
      console.log(formData)

      console.log("e.target.value")
      console.log(e.target.value)

      setUsernamecharerror(true)

      if(e.target.value.length<6){
        setUsernamecharerror(true)
      }
      else{
        setUsernamecharerror(false)
        setStartsearch(true)

      axios.get('/api/users/getallusernames')
      .then(res => {
          // setChapters_array(res.data.user.chapter)
          console.log("axios response")
          console.log(res.data.usernamestaken)
          if(res.data.usernamestaken.toLocaleString().toLowerCase().split(',').includes(e.target.value)){
            console.log("username exists!")
            setUsernameerror(true)
            setStartsearch(false)
            setUsernameAvailable(false)
            setCreatedisable(true)
          }
          else if (!res.data.usernamestaken.toLocaleString().toLowerCase().split(',').includes(e.target.value)){
            console.log("username available")
            setCreatedisable(false)
            setUsernameerror(false)
            setTimeout(() => {
              setStartsearch(false)
              setUsernameAvailable(true)
            }, 1000);
          }

          if(usernameerror === true){
              setUsernameAvailable(false)
          }
      })
      .catch(err => {
          console.log(err.response)
      })
  };

}

  let onChangeEditPermission = (e) => {
    console.log("e.value");
    console.log(e.value);
    let permission = e.value;
    const firstLetter = permission.charAt(0);
    const firstLetterCap = firstLetter.toUpperCase();
    const remainingLetters = permission.slice(1);
    const capitalizedWord = firstLetterCap + remainingLetters;

    setFormData({
      ...formData,
      permission: capitalizedWord,
    });
  };

  let onchangepassword = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });

    console.log("edit profile onchange");
    // this.setState({ [e.target.name]: e.target.value });
    console.log("here we go onchange");

    let hasNumber = /\d/;

    // Does the Password Match?
    if (
      formData.password !== formData.confirmpassword
      // formData.password === formData.confirmpassword
      // && formData.confirmpassword != null
    ) {
      setPasswordMatches(false);
    } else if (formData.password !== e.target.value) {
      setPasswordMatches(false);
    } else if (formData.confirmpassword !== e.target.value) {
      setPasswordMatches(false);
    }

    if (formData.password === e.target.value) {
      setPasswordMatches(true);
    } else if (formData.confirmpassword === e.target.value) {
      setPasswordMatches(true);
    } else if (formData.confirmpassword === formData.password) {
      setPasswordMatches(true);
    }

    if (formData.password !== e.target.value) {
      setPasswordMatches(false);
    }

    // Does the Password Have a Number?
    if (
      hasNumber.test(e.target.value) === true
      // hasNumber.test(formData.password) == true
    ) {
        setPasswordNumber(true)
    } else if (hasNumber.test(formData.password) == false) {
      setPasswordNumber(false);
    }

    // Does the Password Have an UpperCase Character?
    function hasUpperCase(str) {
      return str !== str.toLowerCase();
      }

    if (
      hasUpperCase(e.target.value) === true
    ) {
      setpasswordUppercase(true)

    } else if (hasUpperCase(e.target.value) === false) {
      setpasswordUppercase(false);
    }


    // Is the Password over 5 characters?
    if (e.target.value.length > 5) {
      {
        setPasswordLength(true);
      }
    } else if (e.target.value.length < 5) {
        setPasswordLength(false);
    }

    // Do All 3 Match? Disable Submit Button

    if (
      e.target.value.length > 5 &&
      hasNumber.test(e.target.value) === true &&
      formData.password === e.target.value &&
      hasUpperCase(e.target.value) === true
    ) {
        setDisableNewPassword(false)
        setPasswordMatches(true);
    } else {
        setDisableNewPassword(true)
    }

    // Do All 3 Match Backwards. Disable Submit Button
    if (
      e.target.value.length > 5 &&
      hasNumber.test(e.target.value) === true &&
      formData.confirmpassword === e.target.value &&
      hasUpperCase(e.target.value) === true
    ) {
        setDisableNewPassword(false)
        setPasswordMatches(true);
    }
  };

  let onChangeEditChapters = (selectedOption) => {
    console.log("selectedOption1");
    console.log(selectedOption);
    console.log(selectedOption.length);

    console.log("namaga")
    console.log(selectedOption)

    for(var i = 0; i < selectedOption.length; i++){
    if(selectedOption[i].value.includes("-Create New Chapter-")){
      setRedirectChapter(true);
    }
  }

    let reformattedchapters = [];

    try {
      for (var i = 0; i < selectedOption.length; i++) {
        reformattedchapters.push({
          chapters_assigned: selectedOption[i].value,
        });
      }
    } catch {
      console.log("push error - reformattedchapters");
    }

    // console.log("reformattedchapters");
    // console.log(reformattedchapters);

    setFormData({
      ...formData,
      chapter_volunteer: reformattedchapters,
    });

    console.log("past it");
    // this.setState({ selectedOption });
  };

  let create_volunteer = (formData) => {
    // const newUser = {
    //   email: addemail,
    //   password: addpassword,
    //   name: addname,
    //   associatedemail: associatedemail,
    //   phone: props.user.phone,
    //   address: props.user.address,
    //   city: props.user.city,
    //   state: props.user.state,
    //   zip: props.user.zip,
    //   country: props.user.country,
    //   chapters_assigned: {
    //     chapters_assigned: "test4",
    //   },
    // };

    try {
      // dispatch(actions.registerAdminUser(newUser, history));

      axios
        .post("/api/users/adminregister/", formData)
        .then((res) => {
          let addedVolunteer = [...associatedUsers];

          let addedvolunteer = res.data.user;
          addedVolunteer.push(addedvolunteer);

          setAssociatedUsers(addedVolunteer);

          toast.success(
            <div style={{ fontSize: "16px" }}>
              <b>Volunteer account successfully created!</b>
            </div>,
            {
              position: "top-right",
              autoClose: 2500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
        })
        .catch((err) => {
          console.log("axios frontend error");
          console.log(err);
          toast.error(
            <div style={{ fontSize: "16px" }}>
              <b>Username already exists on EventSensei.com.</b>
              <b>You must choose another username</b>
            </div>,
            {
              position: "top-right",
              autoClose: 2500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
        });
    } catch {
      console.log("registration error");
    }
  };

  let showDeleteModal = (id) => {
    setDeleteModalShow(true);
    setDeletingVolunteer(id);
    console.log(id);
  };

  let [loading, setLoading] = useState(true);

  let deleteuser = (id) => {
    console.log("deletingVolunteer");
    console.log(deletingVolunteer);
    // dispatch(actions.volunteerdelete(deletingVolunteer));

    axios
      .put("/api/users/volunteerdelete/", { id: deletingVolunteer })
      .then((res) => {
        console.log("delete reponse");
        console.log(res.data.user._id);

        let filteredobject = associatedUsers.filter(
          (arr) => arr._id !== res.data.user._id
        );
        setAssociatedUsers(filteredobject);
      })
      .catch((err) => {
        console.log(err);
      });

    setDeleteModalShow(false);

    axios
      .get("/api/users/getadmins")
      .then((res) => {
        console.log(res);
        console.log(res.data.user);
        console.log("all users");
        setAssociatedUsers(res.data.user);
        console.log(associatedUsers);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  let editVolunteer = (id) => {
    console.log(
      "----------editvolunteeragita-1--------------------------------"
    );
    console.log("reduxState");
    console.log(reduxState.auth.user.chapter.map((arr) => arr.chapter_names));
    console.log(id);

    setActualid(id);
    console.log("associatedUsers");
    console.log(associatedUsers);

    let filteredvolunteer = associatedUsers.filter((d) => d._id == id);
    console.log("filteredvolunteeragita");
    console.log(filteredvolunteer);

    setEditedVolunteer(filteredvolunteer);

    setFormData({
      ...formData,
      first_name: filteredvolunteer[0].first_name,
      last_name: filteredvolunteer[0].last_name,
      username: filteredvolunteer[0].username,
      permission: filteredvolunteer[0].role,
      chapter_volunteer: filteredvolunteer[0].chapter_volunteer,
    });

    setCurrentusername(filteredvolunteer[0].username);

    console.log("filteredvolunteer[0].username");
    console.log(filteredvolunteer[0].username);

    const firstLetterRole = filteredvolunteer[0].role.charAt(0);
    const firstLetterRoleCap = firstLetterRole.toUpperCase();
    const remainingLettersRole = filteredvolunteer[0].role.slice(1);
    const properRole = firstLetterRoleCap + remainingLettersRole;

    setPermissionsData({
      value: filteredvolunteer[0].role,
      label: properRole,
    });

    console.log("filteredvolunteer.chapter_volunteer");
    console.log(filteredvolunteer[0].chapter_volunteer);

    let chapters_assigned = filteredvolunteer[0].chapter_volunteer.map(
      (arr) => arr.chapters_assigned
    );
    console.log("chapters_assigned");
    console.log(chapters_assigned);

    let chapterassignedobject = [];

    try {
      for (var i = 0; i < chapters_assigned.length; i++) {
        chapterassignedobject.push({
          value: chapters_assigned[i],
          label: chapters_assigned[i],
        });
      }

      setPermissionAssigned(chapterassignedobject);
    } catch {
      console.log("error");
    }
    // setPermissionsData({
    //   value: filteredvolunteer[0].role,
    //   label: properRole
    // })

    // console.log("username");
    // console.log(filteredvolunteer[0].username);

    // setUsername(filteredvolunteer[0].username);
    // setCurrentusername(filteredvolunteer[0].username);
    setEditModalShow(true);
  };

  let submitEdit = () => {
    const editedUser = {
      id: actualid,
      first_name: formData.first_name,
      last_name: formData.last_name,
      username: formData.username,
      permission: formData.permission,
      chapter_volunteer: formData.chapter_volunteer,
    };

    editedUser.currentusername = currentusername;

    console.log("editedUser");
    console.log(editedUser);

    // console.log("currentusername")
    // console.log(currentusername)

    // this.props.editVolunteer(editedUser)

    axios
      .put("/api/users/editvolunteer/", editedUser)

      .then((res) => {
        console.log("edit axios call succeeeded");
        console.log(res);
        console.log(res.data.user);
        let editedVolunteerId = res.data.user._id;
        let removedEditedVolunteer = associatedUsers.filter(
          (arr) => arr._id === editedVolunteerId
        );
        let remainingObject = associatedUsers.filter(
          (arr) => arr._id !== editedVolunteerId
        );
        console.log("removedEditedVolunteer");
        console.log(removedEditedVolunteer);
        console.log("remainingObject");
        console.log(remainingObject);
        let objecttopush = res.data.user;
        let newobject = remainingObject;
        newobject.push(objecttopush);
        console.log("newobject");
        console.log(newobject);
        setAssociatedUsers(newobject);

        toast.success(
          <div style={{ fontSize: "16px" }}>
            <b>Volunteer sucessfully edited!</b>
          </div>,
          {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );

        setEditModalShow(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error(
          <div style={{ fontSize: "16px" }}>
            <b>Username already exists on EventSensei.com.</b>
            <b>You must choose another username</b>
          </div>,
          {
            position: "top-right",
            autoClose: 4500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      });
  };

  let addModalClose = () => setAddModalShow(false);
  let deleteModalClose = () => setDeleteModalShow(false);
  let editModalClose = () => {
    setEditModalShow(false);
    setShowPassword(false)
    setResetPassword(false)
  }

  let headers = ["Name", "Username", "Permission", "Chapter"];

  const permissionoptions = [
    { value: "owner", label: "Owner" },
    { value: "admin", label: "Admin" },
    { value: "editor", label: "Editor" },
    { value: "volunteer", label: "Volunteer" },
  ];

  const chapteroptions = [{ value: "All Events", label: "All Events" }];

  try {
    let chapters_created = reduxState.auth.user.chapter.map(
      (arr) => arr.chapter_names
    );

    for (var i = 0; i < chapters_created.length; i++) {
      chapteroptions.push({
        value: chapters_created[i],
        label: chapters_created[i],
      });
    }
    chapteroptions.push({
      value: "-Create New Chapter-",
      label: "-Create New Chapter-",
    });
  } catch {
    console.log("error");
  }

  let chapterpage_redirect = null;
  if (redirectChapter) {
    chapterpage_redirect = <Redirect to="/chapters" />;
  }

  let volunteerList = <h1 className="None">No Volunteers...</h1>;

  if (!isEmpty(associatedUsers)) {
    // myData = filteredTasks
    volunteerList = (
      <div>
        <table>
          <thead>
            <tr className="prTableRow" style={{ color: "#404040" }}>
              {headers.map((header) => (
                <th
                  key={header}
                  style={{
                    minWidth: 65,
                    // color: "#484848",
                    color: "#FFFFFF",
                    background: "#404040",
                    paddingTop: 17,
                    paddingLeft: 5,
                    fontFamily: "Verdana",
                    border: ".9px solid darkgray",
                  }}
                >
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {associatedUsers.map((item) => (
              <tr
                key={item._id}
                onClick={(e) => editVolunteer(item._id)}
                className="prTableRow"
                style={{ color: "#404040" }}
              >
                <td
                  key={item.first_name}
                  className="chapterTableCell chapters_field"
                  style={{
                    borderTop: ".9px solid darkgray",
                    borderBottom: ".9px solid darkgray",
                    fontSize: "13px",
                    paddingRight: 18,
                    paddingTop: 18,
                    paddingBottom: 18,
                    paddingLeft: 10,
                    border: ".9px solid darkgray",
                    fontFamily:
                      "Verdana, Arial, Tahoma, Garamond, Trebuchet, Droid Sans",
                    // background: item.status === "Checked In" ? "#b9ecdb" : null,
                  }}
                >
                  {item.first_name}{" "}{item.last_name}
                </td>
                <td
                  key={item.username}
                  className="chapterTableCell chapters_field"
                  style={{
                    borderTop: ".9px solid darkgray",
                    borderBottom: ".9px solid darkgray",
                    fontSize: "13px",
                    paddingRight: 18,
                    paddingTop: 18,
                    paddingBottom: 18,
                    paddingLeft: 10,
                    border: ".9px solid darkgray",
                    fontFamily:
                      "Verdana, Arial, Tahoma, Garamond, Trebuchet, Droid Sans",
                    // background: item.status === "Checked In" ? "#b9ecdb" : null,
                  }}
                >
                  {item.username}{" "}
                </td>
                <td
                  key={item.role}
                  className="chapterTableCell chapters_field"
                  style={{
                    borderTop: ".9px solid darkgray",
                    borderBottom: ".9px solid darkgray",
                    fontSize: "13px",
                    paddingRight: 18,
                    paddingTop: 18,
                    paddingBottom: 18,
                    paddingLeft: 10,
                    border: ".9px solid darkgray",
                    fontFamily:
                      "Verdana, Arial, Tahoma, Garamond, Trebuchet, Droid Sans",
                    // background: item.status === "Checked In" ? "#b9ecdb" : null,
                  }}
                >
                  {item.role}{" "}
                </td>
                <td
                  key={item.chapters_assigned}
                  className="chapterTableCell chapters_field"
                  style={{
                    borderTop: ".9px solid darkgray",
                    borderBottom: ".9px solid darkgray",
                    fontSize: "13px",
                    paddingRight: 18,
                    paddingTop: 18,
                    paddingBottom: 18,
                    paddingLeft: 10,
                    border: ".9px solid darkgray",
                    fontFamily:
                      "Verdana, Arial, Tahoma, Garamond, Trebuchet, Droid Sans",
                    // background: item.status === "Checked In" ? "#b9ecdb" : null,
                  }}
                >
                  {item.chapter_volunteer.length == 1 ? item.chapter_volunteer[0].chapters_assigned
                  :
                  <div>
                  {item.chapter_volunteer.map(
                    (item) =>
                      (item.chapters_assigned.charAt(0).toUpperCase() +
                      item.chapters_assigned.slice(1))
                  ).join(", ")}
                </div>
                  }
                </td>
                <td key={item.chapters_assigned}>
                  {/* marginBottom is increasing height of table cells here */}
                  <div>
                    <i
                      style={{ fontSize: 20 }}
                      className="fas fa-remove fa-xl edit_volunteers_remove"
                      onClick={(e) => {
                        showDeleteModal(item._id);
                        e.stopPropagation();
                        e.preventDefault();
                      }}
                    ></i>
                  </div>
                </td>
                {/* marginBottom is increasing height of table cells here */}
                {/* <td>
                  <button
                    className="volunteerResetPassword"
                    onClick={(e) => editVolunteer(item._id)}
                  >
                    <i className="fas fa-pen fa-xl volunteerResetPasswordPen"></i>
                  </button>
                </td> */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  // let authRedirect = null
  // if(this.props.isAuthenticated){
  //   this.props.history.push('/dashboard')
  // }

  let volunteeredit = (
    <div className="">
      <div>
        {!resetPassword ?
        <Modal
          {...props}
          show={editModalShow}
          onHide={editModalClose}
          backdrop="static"
          keyboard={false}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <div style={{ display: "flex" }}>
                {resetPassword ? (
                  <b color="#909090">Reset Password</b>
                ) : (
                  <b color="#909090">Edit Volunteer</b>
                )}
                {resetPassword ? (
                  <div style={{ display: "flex" }}>
                    <Button
                      onClick={() => setResetPassword(!resetPassword)}
                      style={{ marginLeft: "130%", padding: 6 }}
                    >
                      <b>Edit Volunteer</b>
                    </Button>
                  </div>
                ) : (
                  <div style={{ display: "flex" }}>
                    <Button
                      onClick={() => setResetPassword(!resetPassword)}
                      style={{ marginLeft: "130%", padding: 6 }}
                    >
                      <b>Reset Password</b>
                    </Button>
                  </div>
                )}
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <div style={{ fontFamily: "Verdana, Geneva, Helvetica" }}>
                <form onSubmit={submitEdit}>
                  <div>
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                    <div style={{width: "48%"}} className="form-group">
                      <label htmlFor="name">
                        <b>First Name</b>
                      </label>
                      <input
                        id="first_name"
                        type="text"
                        name="first_name"
                        className="form-control form-control-md"
                        style={{
                          flex: "8",
                          padding: "5px",
                          border: ".5px solid black",
                          width: "98%"
                        }}
                        placeholder="Name..."
                        defaultValue={formData.first_name}
                        onChange={onChangeEdit}
                      />
                    </div>

                    <div style={{width: "48%"}} className="form-group">
                      <label htmlFor="name">
                        <b>Last Name</b>
                      </label>
                      <input
                        id="last_name"
                        type="text"
                        name="last_name"
                        className="form-control form-control-md"
                        style={{
                          flex: "8",
                          padding: "5px",
                          border: ".5px solid black",
                        }}
                        placeholder="Name..."
                        defaultValue={formData.last_name}
                        onChange={onChangeEdit}
                      />
                    </div>
                  </div>

                    <div className="form-group">
                    <div style={{display: "flex"}}>
                    <label htmlFor="email">
                        <b>Username       </b>
                      </label>
                    {startsearch ?
                    <div style={{display: "flex"}}>
                    <span style={{color: "#073980"}}>Checking for Availability.. </span>
                  <RingLoader
                  color={"#073980"}
                  loading={loading}
                  size={20}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  speedMultiplier={1}
                  margin={0}
                />
                </div>
                : null}
                {usernameAvailable ? 
                  <span style={{color: "darkgreen"}}><b>Username Available</b> </span>
                  : null}
                {usernamecharerror ? 
                  <span style={{color: "darkred"}}><b>Username must be over 5 characters</b> </span>
                  : null}
                {usernameerror ?
            <div style={{color: "darkred", marginTop:5, marginLeft: 5, fontWeight: "bold"}}>
              Username not available! Please Choose another Username.</div>
              : null }

                </div>

                      <input
                        id="username"
                        type="text"
                        name="username"
                        className="form-control form-control-md"
                        style={{
                          flex: "8",
                          padding: "5px",
                          border: ".5px solid black",
                        }}
                        placeholder="Username..."
                        defaultValue={formData.username}
                        onChange={onChangeUsernameAdd}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="permission">
                        <b>Permission </b>
                      </label>

                      <Select
                        defaultValue={permissionsData}
                        onChange={onChangeEditPermission}
                        options={permissionoptions}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="chapter">
                        <b>Chapter </b>
                      </label>
                      <Select
                        isMulti
                        defaultValue={permissionAssigned}
                        onChange={onChangeEditChapters}
                        options={chapteroptions}
                      />
                    </div>
                  </div>
                </form>
              </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              style={{
                fontFamily: "Verdana, Geneva, Helvetica",
              }}
              variant="success"
              onClick={submitEdit}
            >
              Submit
            </Button>
            <Button
              style={{
                fontFamily: "Verdana, Geneva, Helvetica",
              }}
              variant="danger"
              onClick={() => {
                setEditModalShow(false)
                setResetPassword(false)
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        : 
        <Modal
        {...props}
        show={editModalShow}
        onHide={editModalClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <div style={{ display: "flex" }}>
              {resetPassword ? (
                <b color="#909090">Reset Password</b>
              ) : (
                <b color="#909090">Edit Volunteer</b>
              )}
              {resetPassword ? (
                <div style={{ display: "flex" }}>
                  <Button
                    onClick={() => setResetPassword(!resetPassword)}
                    style={{ marginLeft: "130%", padding: 6 }}
                  >
                    <b>Edit Volunteer</b>
                  </Button>
                </div>
              ) : (
                <div style={{ display: "flex" }}>
                  <Button
                    onClick={() => setResetPassword(!resetPassword)}
                    style={{ marginLeft: "130%", padding: 6 }}
                  >
                    <b>Reset Password</b>
                  </Button>
                </div>
              )}
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div style={{ fontFamily: "Verdana, Geneva, Helvetica" }}>
              <form onSubmit={submitEdit}>
              <div
      className="changepassprofile"
      style={{
        paddingTop: 10,
        padding: 12,
        paddingLeft: 22,
        borderRadius: 10,
        paddingBottom: 0,
      }}
    >
      <div id="changepassprofile" className="form-group">
        <label htmlFor="password">
          <b style={{ fontFamily: "Verdana, Geneva, Helvetica" }}>
            New Password
          </b>
        </label>

        <div style={{ display: "flex" }}>
          <input
            id="password"
            type={showPassword ? "text" : "password"}
            className="form-control-md"
            name="password"
            style={{
              width: "275px",
              padding: "8px",
              border: ".5px solid black",
              fontFamily: "Verdana, Geneva, Helvetica",
            }}
            // placeholder="Phone..."
            // defaultValue
            onChange={onchangepassword}
            // onBlur={blurPassword}
          />
          <div
            onClick={() => setShowPassword(true)}
            style={{
              textAlign: "center",
              cursor: "pointer",
              border: ".5px solid black",
              padding: 8,
              fontFamily: "Verdana, Geneva, Helvetica",
              width: "60px",
            }}
          >
            Show
          </div>
        </div>
      </div>

      <div className="form-group">
        <label htmlFor="confirmpassword">
          <b style={{ fontFamily: "Verdana, Geneva, Helvetica" }}>
            Confirm New Password
          </b>
        </label>
        <div style={{ display: "flex" }}>
          <input
            id="confirmpassword"
            type={showPassword ? "text" : "password"}
            className="form-control-md"
            name="confirmpassword"
            style={{
              width: "275px",
              padding: "8px",
              border: ".5px solid black",
              fontFamily: "Verdana, Geneva, Helvetica",
            }}
            // placeholder="Phone..."
            // defaultValue
            onChange={onchangepassword}
          />
          <div
            onClick={() => setShowPassword(true)}
            style={{
              textAlign: "center",
              cursor: "pointer",
              border: ".5px solid black",
              padding: 8,
              fontFamily: "Verdana, Geneva, Helvetica",
              width: "60px",
            }}
          >
            Show
          </div>
        </div>
      </div>
      {passwordLength ? (
        <div
          style={{
            color: "darkgreen",
            fontWeight: "bold",
            fontFamily: "Verdana, Geneva, Helvetica",
          }}
        >
          <i className="fas fa-check fa-xl"></i> Atleast 5 Characters
        </div>
      ) : (
        <div
          style={{
            color: "darkred",
            fontWeight: "bold",
            fontFamily: "Verdana, Geneva, Helvetica",
          }}
        >
          <i className="fas fa-remove fa-xl"></i> Atleast 5 Characters
        </div>
      )}

      {passwordNumber ? (
        <div
          style={{
            color: "darkgreen",
            fontWeight: "bold",
            fontFamily: "Verdana, Geneva, Helvetica",
          }}
        >
          <i className="fas fa-check fa-xl"></i> Includes Number
        </div>
      ) : (
        <div
          style={{
            color: "darkred",
            fontWeight: "bold",
            fontFamily: "Verdana, Geneva, Helvetica",
          }}
        >
          <i className="fas fa-remove fa-xl"></i> Includes Number
        </div>
      )}

{passwordUppercase ? (
        <div
          style={{
            color: "darkgreen",
            fontWeight: "bold",
            fontFamily: "Verdana, Geneva, Helvetica",
          }}
        >
          <i className="fas fa-check fa-xl"></i> Includes Uppercase Character
        </div>
      ) : (
        <div
          style={{
            color: "darkred",
            fontWeight: "bold",
            fontFamily: "Verdana, Geneva, Helvetica",
          }}
        >
          <i className="fas fa-remove fa-xl"></i> Includes Uppercase Character
        </div>
      )}

      {passwordMatches ? (
        <div
          style={{
            color: "darkgreen",
            fontWeight: "bold",
            fontFamily: "Verdana, Geneva, Helvetica",
            marginBottom: 0,
          }}
        >
          <i className="fas fa-check fa-xl"></i> Password Matches
        </div>
      ) : (
        <div
          style={{
            color: "darkred",
            fontWeight: "bold",
            fontFamily: "Verdana, Geneva, Helvetica",
            marginBottom: 0,
          }}
        >
          <i className="fas fa-remove fa-xl"></i> Password Matches
        </div>
      )}
      
    </div>
              </form>
            </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
          disabled={disableNewPassword}
            style={{
              fontFamily: "Verdana, Geneva, Helvetica",
            }}
            variant="success"
            onClick={onSubmitResetPassword}
          >
            Submit
          </Button>
          <Button
            style={{
              fontFamily: "Verdana, Geneva, Helvetica",
            }}
            variant="danger"
            onClick={() => {
              setEditModalShow(false)
              setShowPassword(false)
              setResetPassword(false)
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
}

        <DeleteVolunteerModal
          show={deleteModalShow}
          // id={activeItemId}
          onHide={deleteModalClose}
          deletingvolunteer={deletingVolunteer}
          deleteuser={deleteuser}
        />
      </div>

      <ToastContainer
        position="top-left"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );

  return (
    <Fragment>
      {authRedirect}
{chapterpage_redirect}
      <CreateVolunteer
        show={addModalShow}
        onHide={addModalClose}
        create_volunteer={create_volunteer}
      />

      {volunteeredit}

      <div
        style={{
          border: "1px solid darkgray",
          borderRadius: "10px",
          fontFamily: "Verdana, Geneva, Tahoma, sans-serif",
          padding: "25px",
          paddingTop: "15px",
        }}
        className=""
      >
        {/* <h3
          style={{
            fontFamily: "Verdana",
            fontWeight: "bold",
            paddingTop: "1%",
          }}
        >
          Volunteers List
        </h3> */}
      <div style={{display: "flex", justifyContent: "space-between"}}>
        <div>
        <button
          className="createchapterbutton"
          style={{
            marginTop: 10,
            fontFamily: "Verdana, Geneva, Tahoma, sans-serif",
            fontWeight: "bold",
            marginBottom: 20,
          }}
          onClick={() => setAddModalShow(true)}
        >
          Create Volunteers
        </button>
        </div>
        <div
             style={{
              marginTop: 20,
              marginRight: 60,
              fontFamily: "Verdana, Geneva, Tahoma, sans-serif",
              fontWeight: "bold",
            }}>
          <h3 style={{
            fontFamily: "Verdana",
            fontWeight: "bold",
            color: "#505050"
            }}>{associatedUsers.length} Volunteers</h3>
        </div>
        </div>
        {volunteerList}
      </div>
    </Fragment>
  );
};

export default Volunteer;
