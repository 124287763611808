import React, { useState, useEffect, useRef } from "react";
// import React, { useState, useEffect, CSSProperties } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import './Navbar.css'
import Highlight from "./Highlight";
import JsxParser from 'react-jsx-parser'

const NavSearch = () => {
  const reduxState = useSelector((state) => state);
  const history = useHistory();
  const [guestlists, setGuestlists] = useState([]);
  const [guestlistsCopy, setGuestlistsCopy] = useState([]);
  const [detailSearch, setDetailSearch] = useState(false);
  const [searchaga, setSearchaga] = useState("");

  useEffect(() => {

  }, [reduxState.dashboard.dashboard]);

  let ChangeInput = (e) => {
    setSearchaga(e.target.value.toLowerCase())

      // Get names of guests from each list and event id
      console.log("reduxState");
      console.log(reduxState.dashboard.dashboard);
      try{
      let allguests = [];
      let eventids = [];
      let eventsArray = reduxState.dashboard.dashboard;
      for (var i = 0; i < eventsArray.length; i++) {
        allguests = [
          ...allguests,
          ...eventsArray[i].lists.map((item) => item.list),
        ];
  
        let listlength = eventsArray[i].lists.map((item) => item.list).length;
        for (var j = 0; j < listlength; j++) {
          eventids.push(eventsArray[i]._id);
        }
      }
  
      // Get ids of guests from each list
      let allguestids = [];
      console.log("reduxState");
      console.log(reduxState.dashboard.dashboard);
      for (let i = 0; i < eventsArray.length; i++) {
        allguestids = [
          ...allguestids,
          ...eventsArray[i].lists.map((item) => item._id),
        ];
      }
      console.log("allguestidss");
      console.log(allguestids);
  
          // Get Emails of guests from each list
          let allguestemails = [];
          console.log("reduxState");
          console.log(reduxState.dashboard.dashboard);
          for (let i = 0; i < eventsArray.length; i++) {
              allguestemails = [
              ...allguestemails,
              ...eventsArray[i].lists.map((item) => item.email),
            ];
          }
  
      // Get Phones of guests from each list
      let allguestphones = [];
      console.log("reduxState");
      console.log(reduxState.dashboard.dashboard);
      for (let i = 0; i < eventsArray.length; i++) {
          allguestphones = [
          ...allguestphones,
          ...eventsArray[i].lists.map((item) => item.phone),
        ];
      }
  
  
          // Get Organizations of guests from each list
          let allguestorganizations = [];
          console.log("reduxState");
          console.log(reduxState.dashboard.dashboard);
          for (let i = 0; i < eventsArray.length; i++) {
            allguestorganizations = [
              ...allguestorganizations,
              ...eventsArray[i].lists.map((item) => item.organization),
            ];
          }
  
          // Get Ids of all accountids from each list
          let allguestaccountids = [];
          console.log("reduxState");
          console.log(reduxState.dashboard.dashboard);
          for (let i = 0; i < eventsArray.length; i++) {
              allguestaccountids = [
              ...allguestaccountids,
              ...eventsArray[i].lists.map((item) => item.account_id),
            ];
          }
  
  
      let listallguests = [];
  
      for (let i = 0; i < allguests.length; i++) {
        let allkeys = allguestemails[i] + allguestphones[i] + allguestorganizations[i] + allguestaccountids[i] + allguests[i]
        let allsoup = allkeys.toLowerCase()
        listallguests.push({
          event: eventids[i],
          guest: allguestids[i],
          label: allguestaccountids[i] + " - " + allguests[i],
          value: allsoup,
          phone:  allguestphones[i],
          email: allguestemails[i],
          organization: allguestorganizations[i]
        });
      }
  
      console.log("listallguestssss");
      console.log(listallguests);
  
      setGuestlists(listallguests);
      setGuestlistsCopy(listallguests)
  }
  catch{
      console.log("Search error")
  }

    if(e.target.value.length === 0){
      setDetailSearch(false)
      }
    else if(e.target.value.length > 0){
    setDetailSearch(true)
    }
    else{
    setDetailSearch(true)
    }

    console.log(e.target.value.toLowerCase())
    if(guestlists.filter((item)=>item.value.includes(e.target.value.toLowerCase()))){
    let filtered_guest_search = guestlists.filter((item)=>item.value.includes(e.target.value.toLowerCase()))
    setGuestlistsCopy(filtered_guest_search.slice(0, 4))
    }
    else{
      setGuestlistsCopy(guestlists.slice(0, 4))
    }

  }

  return (
    <div style={{display: "flex"}}>
    <div >
      <input
      onChange={ChangeInput} 
      onBlur={()=>{
        setTimeout(() => {
          setDetailSearch(false)
        }, 200);
      }}
      style={{
        height: 40,
        borderRadius: 5,
        fontSize: 16,
        color: "gray",
        paddingLeft: 10,
      }}
      placeholder="Search Guest"
      name="searchinput"
      type="input"
      />

      {detailSearch ? 
          <div
          className="detailsearchcontainer"
          style={{
            position: "absolute",
            cursor: "pointer",
            border: ".9px solid gray",
            borderRadius: 3,
          }}>
            {guestlistsCopy.map((item)=>(
              <div
              style={{
                border: ".9px solid silver",
                paddingTop: 1,
                paddingBottom:2,
                paddingRight: 4
              }}
              onClick={()=>{
                console.log("div pressed")
                history.push(`/guests/${item.event}/${item.guest}`)
              }}
              className="detailsearch"
              >

                <div
                 style={{paddingLeft: 5,
                marginBottom: -2}}
                  onClick={()=>{
                console.log("div pressed")
                history.push(`/guests/${item.event}/${item.guest}`)
              }}>
        <Highlight text={`${item.label}`} searchTerm={searchaga} /><br/>
        </div>

        <div
         style={{paddingLeft: 6,
          fontSize: 13
        }}
         onClick={()=>{
                console.log("div pressed")
                history.push(`/guests/${item.event}/${item.guest}`)
              }}>
        <Highlight text={`${item.email}`} searchTerm={searchaga} /><br/>
        </div>
        {item.phone ?
        <div
        style={{
          paddingLeft: 6,
          fontSize: 13
         }}
        onClick={()=>{
                console.log("div pressed")
                history.push(`/guests/${item.event}/${item.guest}`)
              }}>
        <Highlight text={`${item.phone}`} searchTerm={searchaga} /><br/>
        </div>
        :null}
        {/* <div>{item.email}</div> */}
            </div>
            ))}
          </div>
      :null }
    </div>
    </div>
  );
};

export default NavSearch;
