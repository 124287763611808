import React, { useState, useEffect } from "react";
import List from "../../../components/Dashboards/Lists/List";
import { isEmpty } from "../../../utils/Validation";
import SearchBox from "./SearchBox";
import * as action from "../../../store/actions/index";
import "./Lists.css";
import DeleteListModal from "./DeleteListModal";
import EditGuestModal from "./EditGuestModal";
import Form from "react-bootstrap/Form";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import { Link } from "react-router-dom";
import checkmark from "../../../components/Assets/circlecheck.png";
import { useDispatch, useSelector } from "react-redux";
// import ExportReport from './ExportReport';
// import AddGuestModal from "./AddGuestModal.js";
// import styled from "@emotion/styled";
// import DropdownButton from "react-bootstrap/DropdownButton";
import { Button } from "react-bootstrap";
import { QrReader } from 'react-qr-reader';
import { Redirect } from "react-router-dom";

const Lists = (props) => {
  // Redux Instantiation
  const dispatch = useDispatch();
  const reduxState = useSelector((state) => state);

  // List State
  const [searchTask, setSearchTask] = useState("");
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deletingId, setDeletingId] = useState("");
  const [editing_id, setEditing_id] = useState("");
  const [editing_list, setEditing_list] = useState("");
  const [editing_email, setEditing_email] = useState("");
  const [editing_phone, setEditing_phone] = useState("");
  const [editing_tickettype, setEditing_tickettype] = useState("");
  const [editing_guest_details, setEditing_guest_details] = useState("");
  const [editing_organization, setEditing_organization] = useState("");
  const [editing_table_number, setEditing_table_number] = useState("");
  const [editing_seat_number, setEditing_seat_number] = useState("");
  const [width, setWidth] = useState(window.innerWidth);
  // const [nameSorted, setNameSorted] = useState(true);
  // const [statusSorted, setStatusSorted] = useState(false);
  // const [ticketTypeSorted, setTicketTypeSorted] = useState(false);
  // const [phoneSorted, setPhoneSorted] = useState(false);
  // const [emailSorted, setEmailSorted] = useState(false);
  // const [organizationSorted, setOrganizationSorted] = useState(false);
  // const [tableSorted, setTableSorted] = useState(false);
  const [currentSortField, setCurrentSortField] = useState("Name");
  const [currentSortName, setCurrentSortName] = useState("list_b");
  const [hideChecked, setHideChecked] = useState(false);

  const [data, setData] = useState('No result');
  const [qrscanner, setQrscanner] = useState(false);

  useEffect(() => {

    // axios
    // .get("/api/dashboard/")
    // .then((res) => {
    //   setAxiosGuests(res.data.dashboard[0].lists)
    //   console.log("res---------------------->>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>")
    //   console.log(res)
    //   console.log(axiosGuests)
    //   console.log("res---------------------->>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>")
    // })
    // .catch((err) => {
    //   console.log(err.response);
    // });
    // console.log("<><><><><<<><>><><><><>><><>><><><><><><><><><><><><><><><><><><><><><><><><>><><><><>")
    // console.log("props.lists")
    // console.log(props.lists)
    // console.log("reduxState")
    // console.log(reduxState.dashboard.dashboard[0].lists)
    // console.log("<><><><><<<><>><><><><>><><>><><><><><><><><><><><><><><><><><><><><><><><><>><><><><>")
    // setGuestlist(props.lists.sort((b, a) => (b.list > a.list) ? 1 : ((a.list > b.list) ? -1 : 0)))
    // if (window.innerWidth <= 500) {
    // console.log("less than 500px")
    // this.setState({mobile: true})
    // this.setState({mobileBrowser: true})
    // }
  }, [searchTask]);

  let authRedirect = null;
  if (!reduxState.auth.isAuthenticated) {
    authRedirect = <Redirect to="/" />;
  }
// }, [currentSortName]);

  // const setSortFalse = () => {
  //   setNameSorted(false);
  //   setStatusSorted(false);
  //   setTicketTypeSorted(false);
  //   setPhoneSorted(false);
  //   setEmailSorted(false);
  //   setOrganizationSorted(false);
  //   setTableSorted(false);
  // };

  // const sortField = (header) => {
  //   console.log("header click");
  //   console.log("headerraga")
  //   console.log(header)
  //   // console.log(this.state.guestlist)
  //   setCurrentSortField(header);
  //   setCurrentSortName("Secondd");

  //   if (header === "Name") {
  //     if (nameSorted === true) {
  //       setCurrentSortField("Name");
  //       setCurrentSortName("list_a");
  //       setSortFalse();
  //       dispatch(action.fetchDashboard("list_a"));
  //     } else if (nameSorted === false) {
  //       setCurrentSortField("Name");
  //       setCurrentSortName("list_b");
  //       setSortFalse();
  //       setNameSorted(true);
  //       dispatch(action.fetchDashboard("list_b"));
  //     }
  //   }

  //   if (header === "Status") {
  //     if (statusSorted === true) {
  //       setCurrentSortField("Status");
  //       setCurrentSortName("status_a");
  //       setSortFalse();
  //       setStatusSorted(false);
  //       dispatch(action.fetchDashboard("status_a"));
  //     } else if (statusSorted === false) {
  //       setCurrentSortField("Status");
  //       setCurrentSortName("status_b");
  //       setSortFalse();
  //       setStatusSorted(true);
  //       dispatch(action.fetchDashboard("status_b"));
  //     }
  //   }

  //   if (header === "Ticket Type") {
  //     if (ticketTypeSorted === true) {
  //       setCurrentSortField("Ticket Type");
  //       setCurrentSortName("tickettype_a");
  //       setSortFalse();
  //       setTicketTypeSorted(false);
  //       dispatch(action.fetchDashboard("tickettype_a"));
  //     } else if (ticketTypeSorted === false) {
  //       setCurrentSortField("Ticket Type");
  //       setCurrentSortName("tickettype_b");
  //       setSortFalse();
  //       setTicketTypeSorted(true);
  //       dispatch(action.fetchDashboard("tickettype_b"));
  //     }
  //   }

  //   if (header === "Phone") {
  //     if (phoneSorted === true) {
  //       setCurrentSortField("Phone");
  //       setCurrentSortName("phone_a");
  //       setSortFalse();
  //       setPhoneSorted(false);
  //       dispatch(action.fetchDashboard("phone_a"));
  //     } else if (phoneSorted === false) {
  //       setCurrentSortField("Phone");
  //       setCurrentSortName("phone_b");
  //       setSortFalse();
  //       setPhoneSorted(true);
  //       dispatch(action.fetchDashboard("phone_b"));
  //     }
  //   }

  //   if (header === "Email") {
  //     console.log("Email Run");
  //     if (emailSorted === true) {
  //       console.log("true");
  //       setCurrentSortField("Email");
  //       setCurrentSortName("email_a");
  //       setSortFalse();
  //       setEmailSorted(false);
  //       dispatch(action.fetchDashboard("email_a"));
  //     } else if (emailSorted === false) {
  //       console.log("false");
  //       setCurrentSortField("Email");
  //       setCurrentSortName("email_b");
  //       setSortFalse();
  //       setEmailSorted(true);
  //       dispatch(action.fetchDashboard("email_b"));
  //     }
  //   }

  //   if (header === "Org.") {
  //     if (organizationSorted === true) {
  //       setCurrentSortField("Org.");
  //       setCurrentSortName("organization_a");
  //       setSortFalse();
  //       setOrganizationSorted(false);
  //       dispatch(action.fetchDashboard("organization_a"));
  //     } else if (organizationSorted === false) {
  //       setCurrentSortField("Org.");
  //       setCurrentSortName("organization_b");
  //       setSortFalse();
  //       setOrganizationSorted(true);
  //       dispatch(action.fetchDashboard("organization_b"));
  //     }
  //   }

  //   if (header === "Table #") {
  //     if (tableSorted === true) {
  //       setCurrentSortField("Table #");
  //       setCurrentSortName("table_row_number_a");
  //       dispatch(action.fetchDashboard("table_row_number_a"));
  //       setSortFalse();
  //       setTableSorted(false);
  //     } else if (tableSorted === false) {
  //       setCurrentSortField("Table #");
  //       setCurrentSortName("table_row_number_b");
  //       setSortFalse();
  //       setTableSorted(true);
  //       dispatch(action.fetchDashboard("table_row_number_b"));

  //     }
  //   }

    //   if (header === "Seat #") {
  //     if (tableSorted === true) {
  //       setCurrentSortField("Table #");
  //       setCurrentSortName("seat_number_a");
  //       dispatch(action.fetchDashboard("seat_number_a"));
  //       setSortFalse();
  //       setTableSorted(false);
  //     } else if (tableSorted === false) {
  //       setCurrentSortField("Table #");
  //       setCurrentSortName("seat_number_b");
  //       setSortFalse();
  //       setTableSorted(true);
  //       dispatch(action.fetchDashboard("seat_number_b"));

  //     }
  //   }

  //   //  dispatch(action.fetchDashboard());
  // };

  //remove listener on page exit
  // componentWillUnmount() {
  //   window.removeEventListener('resize', this.getDimensions);
  // }

  //actually set the state to the window dimensions

  // const getDimensions = () => {
  //   this.setState({ width: window.innerWidth, height: window.innerHeight });
  //   console.log(state);
  // };

  // const GuestAddition = (
  //   _id,
  //   list,
  //   email,
  //   phone,
  //   tickettype,
  //   organization,
  //   table_row_number
  //   seat_number
  // ) => {
  //   //Note diffrence _id and id if moving back to Redux
  //   // this.props.addList(
  //   //   id,
  //   //   list,
  //   //   email,
  //   //   phone,
  //   //   tickettype,
  //   //   organization,
  //   //   seat_number
  //   // );

  //   console.log("runnner");
  //   this.props.addGuestReact(
  //     _id,
  //     list,
  //     email,
  //     phone,
  //     tickettype,
  //     organization,
  //     table_row_number
  //   );

  //   // axios.post('/api/lists/create', {_id, list, email, phone, tickettype, organization, table_row_number})
  //   // .then(res => {
  //   //   console.log("res")
  //   //   console.log(res)
  //   //   dispatch(action.fetchDashboard());
  //   // })
  //   // .catch(err => {
  //   //   console.log(err.response)
  //   //   console.log(err.response.data.mesg)
  //   // })

  //   this.setState({ listItem: "" });
  // };

  const initializeDelete = (id) => {
    setDeletingId(id);
    console.log("deletingId");
    console.log(id);
    console.log("state.deletingId");
    console.log(deletingId);
    setDeleteModal(true);
  };

  const initializeEdit = (item) => {
    setEditing_id(item._id);
    setEditing_list(item.list);
    setEditing_email(item.email);
    setEditing_phone(item.phone);
    setEditing_tickettype(item.tickettype);
    setEditing_guest_details(item.guest_details);
    setEditing_organization(item.organization);
    setEditing_table_number(item.table_row_number);
    setEditing_seat_number(item.seat_number);
    setEditModal(true);
  };


  const editGuestList = (dashId, id, text, email, phone, tickettype, guest_details, organization, table_row_number, seat_number) => {
    dispatch(action.editList(dashId, id, text, email, phone, tickettype, guest_details, organization, table_row_number, seat_number, currentSortName));
  };

  // Method to Search and Filter Guests
  const handleinput = (e) => {
    console.log(e.target.value)
    setSearchTask(e.target.value);
  };

   // Method to Delete Guests
  const deleteList = (dashId, id) => {
    console.log(props.dashId)
    console.log(deletingId)
    console.log(currentSortName)
    dispatch(action.deleteList(props.dashId, deletingId, currentSortName));
    // dispatch(action.deleteList(dashId, id, currentSortName));
    setDeleteModal(false);
  };

  // Method to Check Guests In
  const guestCheckboxChange = (item) => {
    console.log("guestCheckboxChange");
    dispatch(action.updateListStatus(props.dashId, item._id, currentSortName));
  };

  // Method to Hide Checked In Guests
  const guestCheckedHide = () => {
    setHideChecked(false)
  };

   // Method to unhide Checked In Guests
  const guestCheckedUnhide = () => {
    setHideChecked(true)
  };


  let closeEditModal = () => setEditModal(false);
  let closeDeleteModal = () => setDeleteModal(false);

  let headers = ["Name", "Org.", "Email",	"Phone", "Table #", "Seat #",	"Ticket Type",	"Status"]

  let filteredTasks = props.lists.filter((el) => {
    return (
      el.list.toLowerCase().includes(searchTask.toLowerCase()) ||
      el.email.toLowerCase().includes(searchTask.toLowerCase()) ||
      el.phone.toLowerCase().includes(searchTask.toLowerCase()) ||
      el.organization.toLowerCase().includes(searchTask.toLowerCase()) 
      // || el.table_row_number.toLowerCase().includes(searchTask.toLowerCase())
    );
  });

  let lists = <h1 className="display-4 None">No Guests.</h1>;

  let mobilelists = <h1 className="display-4 None">No Guests.</h1>;

  if (!isEmpty(props.lists)) {
    // myData = filteredTasks
    mobilelists = filteredTasks
      // .sort((a, b) => a.list.localeCompare(b.list))
      .map((list) => {
        return (
          <List
            dashId={props.dashId}
            key={list._id}
            status={list.status}
            list={list.list}
            email={list.email}
            phone={list.phone}
            tickettype={list.tickettype}
            guest_details={list.guest_details}
            organization={list.organization}
            table_row_number={list.table_row_number}
            seat_number={list.seat_number}
            relationships={list.relationships}
            listId={list._id}
            listDate={list.date}
          />
        );
      });
  }

  lists = (
    <div
      className="guestlistcontainer"
      style={{ paddingRight: -9, background: "white" }}
    >
      <table className="prTable" style={{ marginRight: -5 }}>
        {/* <thead style={{backgroundColor: "#F8F8F8"}}> */}

  {props.lists.length !== 0 ?
        <thead style={{height:"60px"}}>
          <tr className="prTableHeader">
            <th
              // onClick={() => sortField("Name")}
              className=""
              style={{
                color: "#484848",
                paddingTop: 17,
                paddingLeft: 5,
              }}
            ></th>
            <th
              // onClick={() => sortField("Name")}
              className=""
              style={{
                color: "#484848",
                paddingTop: 17,
                paddingLeft: 5,
                minWidth:30
              }}
            >
              Name
              {/* {currentSortField === "Name" ? (
                <div>{nameSorted ? "⬇" : "⬆"}</div>
              ) : null} */}
            </th>
            <th
              // onClick={() => sortField("Org.")}
              className=""
              style={{
                color: "#484848",
                paddingTop: 17,
                paddingLeft: 5,
                minWidth:30
              }}
            >
              Org.
              {/* {currentSortField === "Org." ? (
                <div>{organizationSorted ? "⬇" : "⬆"}</div>
              ) : null} */}
            </th>
            <th
              // onClick={() => sortField("Email")}
              className=""
              style={{
                color: "#484848",
                paddingTop: 17,
                paddingLeft: 5,
                minWidth:30
              }}
            >
              Email
              {/* {currentSortField === "Email" ? (
                <div>{emailSorted ? "⬇" : "⬆"}</div>
              ) : null} */}
            </th>
            <th
              // onClick={() => sortField("Phone")}
              className=""
              style={{
                color: "#484848",
                paddingTop: 17,
                paddingLeft: 5,
                minWidth:30
              }}
            >
              Phone
              {/* {currentSortField === "Phone" ? (
                <div>{phoneSorted ? "⬇" : "⬆"}</div>
              ) : null} */}
            </th>

            <th
              // onClick={() => sortField("Table #")}
              className=""
              style={{
                color: "#484848",
                paddingTop: 17,
                paddingLeft: 5,
                minWidth:30
              }}
            >
              Table #
              {/* {currentSortField === "Table #" ? (
                <div>{tableSorted ? "⬇" : "⬆"}</div>
              ) : null} */}
            </th>

            <th
              // onClick={() => sortField("Table #")}
              className=""
              style={{
                color: "#484848",
                paddingTop: 17,
                paddingLeft: 5,
                minWidth:30
              }}
            >
              Seat #
              {/* {currentSortField === "Seat #" ? (
                <div>{tableSorted ? "⬇" : "⬆"}</div>
              ) : null} */}
            </th>

            <th
              // onClick={() => sortField("Ticket Type")}
              className=""
              style={{
                color: "#484848",
                paddingTop: 17,
                paddingLeft: 5,
                minWidth:30
              }}
            >
              Ticket Type
              {/* {currentSortField === "Ticket Type" ? (
                <div>{ticketTypeSorted ? "⬇" : "⬆"}</div>
              ) : null} */}
            </th>


              {/* Adding Additional Guest Details to Main List */}
            {/* <th
              // onClick={() => sortField("Ticket Type")}
              className=""
              style={{
                color: "#484848",
                paddingTop: 17,
                paddingLeft: 5,
              }}
            >
              Additional Details
            </th> */}

            <th
              // onClick={() => sortField("Status")}
              className=""
              style={{
                color: "#484848",
                paddingTop: 17,
                paddingLeft: 5,
              }}
            >
              Status
              {/* {currentSortField === "Status" ? (
                <div>{statusSorted ? "⬇" : "⬆"}</div>
              ) : null} */}
            </th>
            <th
              className=""
              style={{
                color: "#484848",
                paddingTop: 17,
                paddingLeft: 5,
              }}
            ></th>
            <th
              className=""
              style={{
                color: "#484848",
                paddingTop: 17,
                paddingLeft: 5,
              }}
            ></th>
          </tr>
        </thead>

:
        <thead style={{height:"60px"}}>
          <tr className="">
          <th
              // onClick={() => sortField("Name")}
              className="prTableHeader0"
              style={{
                color: "#484848",
                paddingTop: 17,
                paddingLeft: 5,
              }}
            ></th>
            {headers.map((item)=>

            <th
              // onClick={() => sortField("Name")}
              className="prTableHeaderFields"
              style={{
                color: "#484848",
                paddingTop: 17,
                paddingLeft: 5,
              }}
            >{item}</th>
            )}
          </tr>
        </thead>

 }
   {reduxState.auth.user.role === "Owner Original" || reduxState.auth.user.role === "Owner" || reduxState.auth.user.role === "Admin" || reduxState.auth.user.role === "Editor" ? 
        <tbody>
          {filteredTasks.map((item) => (

            <tr className="prTableRow" style={{ color: "#404040" }}>
              <td
                style={{
                  borderBottom: ".9px solid darkgray",
                  borderTop: ".9px solid darkgray",
                  background: item.status === "Checked In" ? "#b9ecdb" : null,
                  display: item.status ==="Checked In" && hideChecked ? "none" : null,
                  paddingRight: 18,
                }}
                // onClick={(e) => guestCheckboxChange(item)}
                className="prTableCell guestcheckboxcontainer"
              >
                <Form.Check
                  checked={item.status === "Checked In" ? true : false}
                  onChange={(e) => guestCheckboxChange(item)}
                  id="guestcheckbox"
                  type="checkbox"
                />{" "}
              </td>
              <td
                onClick={() => initializeEdit(item)}
                className="prTableCell"
                style={{
                  borderTop: ".9px solid darkgray",
                  borderBottom: ".9px solid darkgray",
                  fontSize: "13px",
                  paddingRight: 18,
                  paddingTop: 18,
                  paddingBottom: 18,
                  paddingLeft: 10,
                  width: "14%",
                  fontFamily:
                    "Verdana, Arial, Tahoma, Garamond, Trebuchet, Droid Sans",
                  background: item.status === "Checked In" ? "#b9ecdb" : null,
                  display: item.status ==="Checked In" && hideChecked ? "none" : null,
                }}
              >
                <div onClick={(e) => console.log(item)}>{item.list}</div>{" "}
              </td>
              <td
                onClick={() => initializeEdit(item)}
                className="prTableCell"
                style={{
                  borderTop: ".9px solid darkgray",
                  borderBottom: ".9px solid darkgray",
                  fontSize: "13px",
                  paddingRight: 18,
                  paddingTop: 18,
                  paddingBottom: 18,
                  paddingLeft: 10,
                  width: "14%",
                  fontFamily:
                    "Verdana, Arial, Tahoma, Garamond, Trebuchet, Droid Sans",
                  background: item.status === "Checked In" ? "#b9ecdb" : null,
                  display: item.status ==="Checked In" && hideChecked ? "none" : null,
                }}
              >
                <div onClick={(e) => console.log(item)}>
                  {item.organization}
                </div>{" "}
              </td>

              <td
                onClick={() => initializeEdit(item)}
                className="prTableCell"
                style={{
                  wordWrap: "break-word",
                  maxWidth: "220px",
                  borderTop: ".9px solid darkgray",
                  borderBottom: ".9px solid darkgray",
                  fontSize: "12px",
                  paddingRight: 18,
                  paddingTop: 18,
                  paddingBottom: 18,
                  paddingLeft: 10,
                  width: "14%",
                  fontFamily:
                    "Verdana, Arial, Tahoma, Garamond, Trebuchet, Droid Sans",
                  background: item.status === "Checked In" ? "#b9ecdb" : null,
                  display: item.status ==="Checked In" && hideChecked ? "none" : null,
                }}
              >
                <div onClick={(e) => console.log(item)}>{item.email}</div>{" "}
              </td>

              <td
                onClick={() => initializeEdit(item)}
                className="prTableCell"
                style={{
                  borderTop: ".9px solid darkgray",
                  borderBottom: ".9px solid darkgray",
                  fontSize: "13px",
                  paddingRight: 18,
                  paddingTop: 18,
                  paddingBottom: 18,
                  paddingLeft: 10,
                  width: "14%",
                  fontFamily:
                    "Verdana, Arial, Tahoma, Garamond, Trebuchet, Droid Sans",
                  background: item.status === "Checked In" ? "#b9ecdb" : null,
                  display: item.status ==="Checked In" && hideChecked ? "none" : null,
                }}
              >
                <div onClick={(e) => console.log(item)}>{item.phone}</div>{" "}
              </td>

              <td
                onClick={() => initializeEdit(item)}
                className="prTableCell"
                style={{
                  borderTop: ".9px solid darkgray",
                  borderBottom: ".9px solid darkgray",
                  fontSize: "13px",
                  paddingRight: 18,
                  paddingTop: 18,
                  paddingBottom: 18,
                  paddingLeft: 10,
                  width: "14%",
                  fontFamily:
                    "Verdana, Arial, Tahoma, Garamond, Trebuchet, Droid Sans",
                  background: item.status === "Checked In" ? "#b9ecdb" : null,
                  display: item.status ==="Checked In" && hideChecked ? "none" : null,
                }}
              >
                <div onClick={(e) => console.log(item)}>
                  {item.table_row_number}
                </div>{" "}
              </td>

              <td
                onClick={() => initializeEdit(item)}
                className="prTableCell"
                style={{
                  borderTop: ".9px solid darkgray",
                  borderBottom: ".9px solid darkgray",
                  fontSize: "13px",
                  paddingRight: 18,
                  paddingTop: 18,
                  paddingBottom: 18,
                  paddingLeft: 10,
                  width: "14%",
                  fontFamily:
                    "Verdana, Arial, Tahoma, Garamond, Trebuchet, Droid Sans",
                  background: item.status === "Checked In" ? "#b9ecdb" : null,
                  display: item.status ==="Checked In" && hideChecked ? "none" : null,
                }}
              >
                <div onClick={(e) => console.log(item)}>
                  {item.seat_number}
                </div>{" "}
              </td>
              <td
                onClick={() => initializeEdit(item)}
                className="prTableCell"
                style={{
                  borderTop: ".9px solid darkgray",
                  borderBottom: ".9px solid darkgray",
                  fontSize: "13px",
                  paddingRight: 18,
                  paddingTop: 18,
                  paddingBottom: 18,
                  paddingLeft: 10,
                  width: "14%",
                  fontFamily:
                    "Verdana, Arial, Tahoma, Garamond, Trebuchet, Droid Sans",

                  background: item.status === "Checked In" ? "#b9ecdb" : null,
                  display: item.status ==="Checked In" && hideChecked ? "none" : null,
                }}
              >
                <div onClick={(e) => console.log(item)}>{item.tickettype}</div>{" "}
              </td>


                {/* Add Additionl Guest Details */}
              {/* <td
                onClick={() => initializeEdit(item)}
                className="prTableCell"
                style={{
                  borderTop: ".9px solid darkgray",
                  borderBottom: ".9px solid darkgray",
                  fontSize: "13px",
                  paddingRight: 18,
                  paddingTop: 18,
                  paddingBottom: 18,
                  paddingLeft: 10,
                  width: "14%",
                  fontFamily:
                    "Verdana, Arial, Tahoma, Garamond, Trebuchet, Droid Sans",

                  background: item.status === "Checked In" ? "#b9ecdb" : null,
                  display: item.status ==="Checked In" && hideChecked ? "none" : null,
                }}
              >
                <div onClick={(e) => console.log(item)}>{item.guest_details}</div>{" "}
              </td> */}


              <td
                onClick={() => initializeEdit(item)}
                className="prTableCell"
                style={{
                  borderTop: ".9px solid darkgray",
                  borderBottom: ".9px solid darkgray",
                  fontSize: "13px",
                  paddingRight: 18,
                  paddingTop: 18,
                  paddingBottom: 18,
                  paddingLeft: 10,
                  width: "14%",
                  fontFamily:
                    "Verdana, Arial, Tahoma, Garamond, Trebuchet, Droid Sans",
                  background: item.status === "Checked In" ? "#b9ecdb" : null,
                  display: item.status ==="Checked In" && hideChecked ? "none" : null,
                  color: item.status === "Checked In" ? "darkgreen" : null,
                  fontWeight: item.status === "Checked In" ? "bold" : null,
                }}
              >
                <div style={{ display: "flex" }}>
                  <div onClick={(e) => console.log(item)}>
                    {item.status}
                    {/* {item.status === "Checked In" ? " ✔️" : null} */}
                    {item.status === "Checked In" ? (
                      <img
                        src={checkmark}
                        position="absolute"
                        width="25"
                        height="25"
                        alt="qr code scanning"
                        className="checkmark_image"
                        style={{
                          color: "black",
                          padding: 2,
                          maxWidth: "100%",
                          marginLeft: "auto",
                          marginRight: "auto",
                          marginBottom: "1%",
                        }}
                      />
                    ) : null}

                    {/* {item.status === "Checked In" ? " ✅" : null} */}
                  </div>
                </div>
              </td>
              {reduxState.auth.user.role === "Owner Original" || reduxState.auth.user.role === "Owner" || reduxState.auth.user.role === "Admin" ? 
              <td
                className="prTableCell"
                style={{
                  borderTop: ".9px solid darkgray",
                  borderBottom: ".9px solid darkgray",
                  background: item.status === "Checked In" ? "#b9ecdb" : null,
                  display: item.status ==="Checked In" && hideChecked ? "none" : null,
                }}
              >
                {/* marginBottom is increasing height of table cells here */}
                <i
                  className="fas fa-trash fa-xl edit_volunteers_remove"
                  // onClick={(e) =>console.log(item)}
                  // onClick={(e) => this.setState({deleteModal: true})}
                  onClick={() => initializeDelete(item._id)}
                ></i>
              </td>
            : null}
              {/* <td style={{background: item.status === "Checked In" ? '#b9ecdb': null}}>
              <i onClick={() => initializeEdit(item)} className="fas fa-pen fa-xl volunteerResetPasswordPen"></i>
            
              </td> */}
            </tr>
            
          ))}
        </tbody>

:
<tbody>
{props.lists.map((item) => (

  <tr className="prTableRow" style={{ color: "#404040" }}>
    <td
      style={{
        borderBottom: ".9px solid darkgray",
        borderTop: ".9px solid darkgray",
        background: item.status === "Checked In" ? "#b9ecdb" : null,
        display: item.status ==="Checked In" && hideChecked ? "none" : null,
        paddingRight: 18,
      }}
      // onClick={(e) => guestCheckboxChange(item)}
      className="prTableCell guestcheckboxcontainer"
    >
      <Form.Check
        checked={item.status === "Checked In" ? true : false}
        onChange={(e) => guestCheckboxChange(item)}
        id="guestcheckbox"
        type="checkbox"
      />{" "}
    </td>
    <td
      // onClick={() => initializeEdit(item)}
      className="prTableCell"
      style={{
        borderTop: ".9px solid darkgray",
        borderBottom: ".9px solid darkgray",
        fontSize: "13px",
        paddingRight: 18,
        paddingTop: 18,
        paddingBottom: 18,
        paddingLeft: 10,
        width: "14%",
        fontFamily:
          "Verdana, Arial, Tahoma, Garamond, Trebuchet, Droid Sans",
        background: item.status === "Checked In" ? "#b9ecdb" : null,
        display: item.status ==="Checked In" && hideChecked ? "none" : null,
      }}
    >
      <div onClick={(e) => console.log(item)}>{item.list}</div>{" "}
    </td>
    <td
      // onClick={() => initializeEdit(item)}
      className="prTableCell"
      style={{
        borderTop: ".9px solid darkgray",
        borderBottom: ".9px solid darkgray",
        fontSize: "13px",
        paddingRight: 18,
        paddingTop: 18,
        paddingBottom: 18,
        paddingLeft: 10,
        width: "14%",
        fontFamily:
          "Verdana, Arial, Tahoma, Garamond, Trebuchet, Droid Sans",
        background: item.status === "Checked In" ? "#b9ecdb" : null,
        display: item.status ==="Checked In" && hideChecked ? "none" : null,
      }}
    >
      <div onClick={(e) => console.log(item)}>
        {item.organization}
      </div>{" "}
    </td>

    <td
      // onClick={() => initializeEdit(item)}
      className="prTableCell"
      style={{
        wordWrap: "break-word",
        maxWidth: "220px",
        borderTop: ".9px solid darkgray",
        borderBottom: ".9px solid darkgray",
        fontSize: "12px",
        paddingRight: 18,
        paddingTop: 18,
        paddingBottom: 18,
        paddingLeft: 10,
        width: "14%",
        fontFamily:
          "Verdana, Arial, Tahoma, Garamond, Trebuchet, Droid Sans",
        background: item.status === "Checked In" ? "#b9ecdb" : null,
        display: item.status ==="Checked In" && hideChecked ? "none" : null,
      }}
    >
      <div onClick={(e) => console.log(item)}>{item.email}</div>{" "}
    </td>

    <td
      // onClick={() => initializeEdit(item)}
      className="prTableCell"
      style={{
        borderTop: ".9px solid darkgray",
        borderBottom: ".9px solid darkgray",
        fontSize: "13px",
        paddingRight: 18,
        paddingTop: 18,
        paddingBottom: 18,
        paddingLeft: 10,
        width: "14%",
        fontFamily:
          "Verdana, Arial, Tahoma, Garamond, Trebuchet, Droid Sans",
        background: item.status === "Checked In" ? "#b9ecdb" : null,
        display: item.status ==="Checked In" && hideChecked ? "none" : null,
      }}
    >
      <div onClick={(e) => console.log(item)}>{item.phone}</div>{" "}
    </td>

    <td
      // onClick={() => initializeEdit(item)}
      className="prTableCell"
      style={{
        borderTop: ".9px solid darkgray",
        borderBottom: ".9px solid darkgray",
        fontSize: "13px",
        paddingRight: 18,
        paddingTop: 18,
        paddingBottom: 18,
        paddingLeft: 10,
        width: "14%",
        fontFamily:
          "Verdana, Arial, Tahoma, Garamond, Trebuchet, Droid Sans",
        background: item.status === "Checked In" ? "#b9ecdb" : null,
        display: item.status ==="Checked In" && hideChecked ? "none" : null,
      }}
    >
      <div onClick={(e) => console.log(item)}>
        {item.table_row_number}
      </div>{" "}
    </td>

    <td
      // onClick={() => initializeEdit(item)}
      className="prTableCell"
      style={{
        borderTop: ".9px solid darkgray",
        borderBottom: ".9px solid darkgray",
        fontSize: "13px",
        paddingRight: 18,
        paddingTop: 18,
        paddingBottom: 18,
        paddingLeft: 10,
        width: "14%",
        fontFamily:
          "Verdana, Arial, Tahoma, Garamond, Trebuchet, Droid Sans",
        background: item.status === "Checked In" ? "#b9ecdb" : null,
        display: item.status ==="Checked In" && hideChecked ? "none" : null,
      }}
    >
      <div onClick={(e) => console.log(item)}>
        {item.seat_number}
      </div>{" "}
    </td>
    <td
      // onClick={() => initializeEdit(item)}
      className="prTableCell"
      style={{
        borderTop: ".9px solid darkgray",
        borderBottom: ".9px solid darkgray",
        fontSize: "13px",
        paddingRight: 18,
        paddingTop: 18,
        paddingBottom: 18,
        paddingLeft: 10,
        width: "14%",
        fontFamily:
          "Verdana, Arial, Tahoma, Garamond, Trebuchet, Droid Sans",

        background: item.status === "Checked In" ? "#b9ecdb" : null,
        display: item.status ==="Checked In" && hideChecked ? "none" : null,
      }}
    >
      <div onClick={(e) => console.log(item)}>{item.tickettype}</div>{" "}
    </td>


      {/* Add Additionl Guest Details */}
    {/* <td
      // onClick={() => initializeEdit(item)}
      className="prTableCell"
      style={{
        borderTop: ".9px solid darkgray",
        borderBottom: ".9px solid darkgray",
        fontSize: "13px",
        paddingRight: 18,
        paddingTop: 18,
        paddingBottom: 18,
        paddingLeft: 10,
        width: "14%",
        fontFamily:
          "Verdana, Arial, Tahoma, Garamond, Trebuchet, Droid Sans",

        background: item.status === "Checked In" ? "#b9ecdb" : null,
        display: item.status ==="Checked In" && hideChecked ? "none" : null,
      }}
    >
      <div onClick={(e) => console.log(item)}>{item.guest_details}</div>{" "}
    </td> */}


    <td
      // onClick={() => initializeEdit(item)}
      className="prTableCell"
      style={{
        borderTop: ".9px solid darkgray",
        borderBottom: ".9px solid darkgray",
        fontSize: "13px",
        paddingRight: 18,
        paddingTop: 18,
        paddingBottom: 18,
        paddingLeft: 10,
        width: "14%",
        fontFamily:
          "Verdana, Arial, Tahoma, Garamond, Trebuchet, Droid Sans",
        background: item.status === "Checked In" ? "#b9ecdb" : null,
        display: item.status ==="Checked In" && hideChecked ? "none" : null,
        color: item.status === "Checked In" ? "darkgreen" : null,
        fontWeight: item.status === "Checked In" ? "bold" : null,
      }}
    >
      <div style={{ display: "flex" }}>
        <div onClick={(e) => console.log(item)}>
          {item.status}
          {/* {item.status === "Checked In" ? " ✔️" : null} */}
          {item.status === "Checked In" ? (
            <img
              src={checkmark}
              position="absolute"
              width="25"
              height="25"
              alt="qr code scanning"
              className="checkmark_image"
              style={{
                color: "black",
                padding: 2,
                maxWidth: "100%",
                marginLeft: "auto",
                marginRight: "auto",
                marginBottom: "1%",
              }}
            />
          ) : null}

          {/* {item.status === "Checked In" ? " ✅" : null} */}
        </div>
      </div>
    </td>
    {reduxState.auth.user.role === "Owner Original" || reduxState.auth.user.role === "Owner" || reduxState.auth.user.role === "Admin"  ? 
    <td
      className="prTableCell"
      style={{
        borderTop: ".9px solid darkgray",
        borderBottom: ".9px solid darkgray",
        background: item.status === "Checked In" ? "#b9ecdb" : null,
        display: item.status ==="Checked In" && hideChecked ? "none" : null,
      }}
    >
      {/* marginBottom is increasing height of table cells here */}
      <i
        className="fas fa-trash fa-xl edit_volunteers_remove"
        // onClick={(e) =>console.log(item)}
        // onClick={(e) => this.setState({deleteModal: true})}
        onClick={() => initializeDelete(item._id)}
      ></i>
    </td>
  : null}
    {/* <td style={{background: item.status === "Checked In" ? '#b9ecdb': null}}>
    <i onClick={() => initializeEdit(item)} className="fas fa-pen fa-xl volunteerResetPasswordPen"></i>
  
    </td> */}
  </tr>
  
))}
</tbody>}


      </table>
    </div>
  );

  return (
    <div>
      {authRedirect}
      <div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <SearchBox handleInput={handleinput} />

          {/* Another Type of React BootStrap Dropdown */}
          {/* <DropdownButton
            as={ButtonGroup}
            id="dropdownactionslist"
            title="Actions"
          >
            <Dropdown.Item eventKey="1">Action</Dropdown.Item>
            <Dropdown.Item eventKey="2">Another action</Dropdown.Item>
            <Dropdown.Item eventKey="3" active>
              Active Item
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item eventKey="4">Separated link</Dropdown.Item>
          </DropdownButton> */}
        <div>

    
    {/* {!qrscanner ? 
        <Button 
            className="unhide_checkedin"
            onClick={()=>setQrscanner(true)}
              style={{
                border: "1px solid #A9A9A9",
                background: "whitesmoke",
                }} id="hidecheckin" >QR Scanner
            </Button>
        : 
        <Button 
        className="unhide_checkedin"
        onClick={()=>setQrscanner(false)}
          style={{
            border: "1px solid #A9A9A9",
            fontWeight: "bold",
            color: "darkred",
            background: "whitesmoke",
            }} id="hidecheckin" >Hide QR Scanner
        </Button>
        } */}
          {hideChecked?
            <Button 
            className="unhide_checkedin"
            onClick={guestCheckedHide}
              style={{
                border: "1px solid #A9A9A9",
                fontWeight: "bold",
                color: "darkred",
                background: "#C8C8C8",
                }} id="hidecheckin" >Unhide Checked-In
            </Button>
              :
            <Button 
            onClick={guestCheckedUnhide}
              style={{
                border: "1px solid #A9A9A9",
                // background: "#C8C8C8",
                }}id="hidecheckin" >Hide Checked-In
            </Button>
          }

          <Dropdown as={ButtonGroup}>
            {/* <Button 
              style={{
                borderTop: "1px solid #A0A0A0",
                borderBottom: "1px solid #A0A0A0",
                borderLeft: "1px solid #A0A0A0"
                }} id="dropdownactionslist" >Actions</Button> */}
            <Dropdown.Toggle
              style={{
                border: "1px solid #A9A9A9",
              }}
              split
              id="dropdownactionslist"
            >
              <span style={{ marginRight: "12px", paddingLeft: "6px" }}>
                Actions{" "}
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu className="super-colors">
              <Dropdown.Item eventKey="1">
                <Link
                  data-toggle="collapse"
                  data-target="#navbarNav"
                  style={{
                    textDecoration: "none",
                    color: "black",
                  }}
                  to="/imports"
                >
                  Import Guests
                </Link>
              </Dropdown.Item>
              <Dropdown.Item eventKey="2">
                <Link
                  data-toggle="collapse"
                  data-target="#navbarNav"
                  style={{
                    textDecoration: "none",
                    color: "black",
                  }}
                  to="/reports"
                >
                  Download Report
                </Link>
              </Dropdown.Item>
              <Dropdown.Item eventKey="3">
                <Link
                  data-toggle="collapse"
                  data-target="#navbarNav"
                  style={{
                    textDecoration: "none",
                    color: "black",
                  }}
                  to="/invite"
                >
                  Invite Guests
                </Link>
              </Dropdown.Item>
              {/* <Dropdown.Divider /> */}
              <Dropdown.Item eventKey="4">
                <Link
                  data-toggle="collapse"
                  data-target="#navbarNav"
                  style={{
                    textDecoration: "none",
                    color: "black",
                  }}
                  to="/analytics"
                >
                  View Analytics
                </Link>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        </div>

        <>
        {qrscanner ? 
      <QrReader
      containerStyle={{height: 0, width: 400, float: "right"}}
        onResult={(result, error) => {
          if (!!result) {
            setData(result?.text);
          }

          if (!!error) {
            console.info(error);
          }
        }}
        style={{ width: '100%' }}
      />
      : null}
      {/* <p>{data}</p> */}
    </>

        {width <= 400 ? 
        <ul className="list-group ListGroup">
        {mobilelists}
         </ul>
        : 
        <div>
         {lists}
         </div>
        }

      </div>
      {/* Strange error here. Modal shows extra blank space even when not showing. */}
      {deleteModal ? (
        <DeleteListModal
          deleteList={deleteList}
          show={deleteModal}
          dashId={props.dashId}
          id={deletingId}
          onHide={closeDeleteModal}
        />
      ) : null}

      {/* {this.state.editModal? */}
      <EditGuestModal
        show={editModal}
        onHide={closeEditModal}
        dashId={props.dashId}
        id={editing_id}
        email={editing_email}
        phone={editing_phone}
        tickettype={editing_tickettype}
        guest_details={editing_guest_details}
        organization={editing_organization}
        table_row_number={editing_table_number}
        seat_number={editing_seat_number}
        editGuestList={editGuestList}
        list={editing_list}
      />
    </div>
  );
};

export default Lists;

// const AddGuestButton = styled.button`
//   width: 100%;
//   height: 35px;
//   background: whitesmoke;
//   font-weight: bold;
//   cursor: pointer;
//   border: 1px solid darkgray;
//   fontsize: 18px;

//   @media screen and (max-width: 900px) {
//     width: 100%;
//     margin-left: 0%;
//   }

//   @media screen and (max-width: 400px) {
//     width: 99%;
//   }
// `;

// const listStyle = {
//   borderBottom: ".9px solid darkgray",
//   fontSize: "13px",
//   paddingRight: 18,
//   paddingTop: 18,
//   paddingBottom: 18,
//   paddingLeft: 10,
//   width: "14%",
//   fontFamily: "Verdana, Arial, Tahoma, Garamond, Trebuchet, Droid Sans",
// };